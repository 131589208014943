import { RESULT_CURRENT_REPLACEMENT, RESULT_TOTAL_REPLACEMENT } from './constants';

const getResultsQuantityItemsInfo = (
  itemsTotal: number,
  itemsCurrent: number,
  title: string
): string =>
  title
    .replace(RESULT_CURRENT_REPLACEMENT, String(itemsCurrent))
    .replace(RESULT_TOTAL_REPLACEMENT, String(itemsTotal));

const helpers = {
  getResultsQuantityItemsInfo,
};

export default helpers;
