import { RESULT_NUMBER_REPLACEMENT } from './constants';

const getResultsBtnLabel = (
  itemsTotal: number,
  singleTitle: string,
  multipleTitle: string
): string => {
  if (itemsTotal === 1) {
    return singleTitle.replace(RESULT_NUMBER_REPLACEMENT, String(itemsTotal));
  }

  return multipleTitle.replace(RESULT_NUMBER_REPLACEMENT, String(itemsTotal));
};

const helpers = {
  getResultsBtnLabel,
};

export default helpers;
