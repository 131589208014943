import { Algolia, UmbracoFilterBlock, UmbracoTags } from '@shared/types';

import {
  ALGOLIA_URL_PARAMS,
  getUrlToSet,
  isActiveRefinement,
} from 'components/algolia/commonHelpers';
import { getRemovedParamFromLocationQueryString, setUrl } from 'utils/browser';

const getChosenFilterItems = (
  blocks: UmbracoFilterBlock.IStructure[] | null,
  refinementList?: Algolia.IRefinementList
): Algolia.IChosenFilterItemsResult => {
  const data: Algolia.IChosenFilterItemsResult = {
    chosenFilterItems: [],
    chosenFilterIds: [],
    chosenBlockIds: [],
  };

  if (!refinementList || !blocks) {
    return data;
  }

  blocks.forEach((block: UmbracoFilterBlock.IStructure) => {
    block.filterItems.forEach((item: UmbracoTags.IEntityTag) => {
      if (!isActiveRefinement(refinementList, String(item.id), block.attributeForFaceting)) {
        return;
      }

      data.chosenFilterItems.push({
        title: item.title,
        itemUrlParameter: item.urlParameter,
        itemId: item.id,
        blockId: block.attributeForFaceting,
        blockUrlParameter: block.urlParameter,
      });
      data.chosenFilterIds.push(String(item.id));

      if (!data.chosenBlockIds.includes(block.attributeForFaceting)) {
        data.chosenBlockIds.push(block.attributeForFaceting);
      }
    });
  });

  return data;
};

const getAttributeForFacetingByCategory = (
  blocks: UmbracoFilterBlock.IStructure[] | null,
  category?: number
): string | undefined => {
  if (!category || !blocks?.length) {
    return '';
  }

  return blocks.find((block) => block.filterItems.find((filterItem) => filterItem.id === category))
    ?.attributeForFaceting;
};

const getDefaultFiltersParams = (
  lang: string,
  category: number,
  categoryFaceting?: string,
  additionalFilterParams?: string
): string =>
  `${categoryFaceting ? `${categoryFaceting}:${category} AND ` : ''}lang:${lang}${
    additionalFilterParams || ''
  }`;

const setFilteredUrlParams = (
  sections: UmbracoFilterBlock.IStructure[] | null,
  refinementList?: Algolia.IRefinementList
): void => {
  const { chosenFilterItems } = getChosenFilterItems(sections, refinementList);

  let url = getUrlToSet(chosenFilterItems);

  if (!url) {
    url = getRemovedParamFromLocationQueryString(ALGOLIA_URL_PARAMS.filterSection);
  }

  setUrl(url || '');
};

const helpers = {
  getChosenFilterItems,
  getDefaultFiltersParams,
  setFilteredUrlParams,
  getAttributeForFacetingByCategory,
};

export default helpers;
