import React, { FC, ReactElement } from 'react';
import { Algolia } from '@shared/types';

import Button from 'components/elements/Button';
import Typography from 'components/elements/Typography';
import IconCustom from 'components/helpers/IconCustom';

import helpers from './helpers';
import { IPropsChosenFiltersList } from './models';

import './ChosenFiltersList.scss';

const ChosenFiltersList: FC<IPropsChosenFiltersList> = ({
  items,
  handleRemoveSelectionData,
  removeAppliedFilterAriaLabel,
  handleResetSelectionData,
  clearAllBtn,
  quantityItemsInfo,
  itemsTotal,
  itemsCurrent,
  isSmallDevice,
}): ReactElement => (
  <div data-testid="ChosenFiltersList" className="chosen-filters-list">
    <div className="chosen-filters-list__items">
      {items?.map((item: Algolia.IChosenFilterItem) => (
        <div key={`${item.blockId}_${item.itemId}`} className="chosen-filters-list__item">
          <span>{item.title}</span>
          <button
            type="button"
            aria-label={`${removeAppliedFilterAriaLabel || ''}${item.title}`}
            onClick={handleRemoveSelectionData(item.blockId, item.itemId)}
            className="chosen-filters-list__btn"
          >
            <IconCustom icon="exit" />
          </button>
        </div>
      ))}
    </div>

    {quantityItemsInfo?.length ? (
      <Typography
        data={[
          {
            ...quantityItemsInfo[0],
            text: helpers.getResultsQuantityItemsInfo(
              itemsTotal,
              itemsCurrent,
              quantityItemsInfo[0].text
            ),
          },
        ]}
        customClass="chosen-filters-list__quantity-items-info"
      />
    ) : null}

    {items?.length && !isSmallDevice ? (
      <Button data={clearAllBtn} clickHandler={handleResetSelectionData} />
    ) : null}
  </div>
);

export default ChosenFiltersList;
