import React, { FC, ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Configure, InstantSearch } from 'react-instantsearch-dom';
import { Algolia, UmbracoFilterBlock } from '@shared/types';
import algoliasearch from 'algoliasearch/lite';
import classNames from 'classnames';
import useClickOutside from 'hooks/useClickOutside';

import AlgoliaCustomHits from 'components/algolia/AlgoliaCustomHits';
import ChosenFiltersList from 'components/algolia/ChosenFiltersList';
import {
  DefaultSearchState,
  getSavedSelectionDataToSet,
  HitsPerPage,
  removeItemInRefinementList,
} from 'components/algolia/commonHelpers';
import { parseFilterBlocks } from 'components/algolia/commonHelpers/parsers';
import FilterPanel from 'components/algolia/FilterPanel';
import Button from 'components/elements/Button';
import { isBrowser } from 'utils/browser';
import { DEFAULT_PAGINATION_START_PAGE } from 'utils/constants';

import helpers from './helpers';
import { IPropsAlgoliaFilters } from './models';

import './AlgoliaFilters.scss';

const searchClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID as string,
  process.env.GATSBY_ALGOLIA_SEARCH_PUBLIC_KEY as string
);

const AlgoliaFilters: FC<IPropsAlgoliaFilters> = ({
  filtersSection,
  indexName,
  saveAlgoliaHitsResponse,
  handleAlgoliaFiltersUsed,
  handleHitsResponseActivated,
  handleActiveListPage,
  isHitsResponseActivated,
  lang,
  isSmallDevice,
  itemsTotal,
  itemsCurrent,
  category,
}): ReactElement | null => {
  const [searchState, setSearchState] = useState<Algolia.ISearchState>(DefaultSearchState);
  const [isSidebarOpen, setSidebarOpen] = useState<boolean>(false);
  const wrapperRef = useRef(null);

  const filtersSectionData = filtersSection?.[0];
  const filterBlocks = useMemo(
    () => parseFilterBlocks(filtersSectionData.filterBlocks),
    [filtersSectionData.filterBlocks]
  );

  const categoryFaceting = useMemo(
    () => helpers.getAttributeForFacetingByCategory(filterBlocks, category),
    [filterBlocks, category]
  );

  const savedSelectionData = getSavedSelectionDataToSet(filterBlocks);
  useEffect(() => {
    if (!savedSelectionData) {
      setSearchState(DefaultSearchState);
    }
  }, [savedSelectionData]);

  const { chosenFilterItems, chosenFilterIds } = useMemo(
    () => helpers.getChosenFilterItems(filterBlocks, searchState.refinementList),
    [filterBlocks, searchState.refinementList]
  );

  const handleActiveFiltersIdsOnLoad = useCallback(
    (filterBlocksData: UmbracoFilterBlock.IStructure[]) => {
      const newSearchState = getSavedSelectionDataToSet(filterBlocksData);
      if (!newSearchState) {
        return;
      }
      handleAlgoliaFiltersUsed(true);
      setSearchState(newSearchState);
    },
    []
  );

  useEffect(() => {
    if (!filterBlocks) {
      return;
    }

    handleActiveFiltersIdsOnLoad(filterBlocks);
  }, [filterBlocks]);

  const handleResetSelectionData = useCallback(() => {
    if (chosenFilterItems.length) {
      setSearchState({ ...DefaultSearchState });
    }
    helpers.setFilteredUrlParams(filterBlocks);

    // Switch to the main page on every filters changing
    handleActiveListPage(DEFAULT_PAGINATION_START_PAGE);
  }, [chosenFilterItems.length]);

  const checkAlgoliaFiltersUsage = useCallback((newSearchState: Algolia.ISearchState) => {
    if (!newSearchState?.refinementList) {
      handleAlgoliaFiltersUsed(false);

      return;
    }

    if (Object.keys(newSearchState.refinementList).length) {
      handleAlgoliaFiltersUsed(true);

      return;
    }

    handleAlgoliaFiltersUsed(false);
  }, []);

  const handleOnSearchStateChange = useCallback((newSearchState: Algolia.ISearchState) => {
    helpers.setFilteredUrlParams(filterBlocks, newSearchState.refinementList);
    checkAlgoliaFiltersUsage(newSearchState);
    setSearchState(newSearchState);

    // Switch to the main page on every filters changing
    handleActiveListPage(DEFAULT_PAGINATION_START_PAGE);
  }, []);

  const handleRemoveSelectionData = useCallback(
    (blockId: string, itemId: number) => () => {
      setSearchState((oldState: Algolia.ISearchState) => {
        const newSearchState = {
          ...oldState,
          refinementList: {
            ...oldState.refinementList,
          },
        };

        const sectionData = removeItemInRefinementList(
          oldState?.refinementList?.[blockId],
          String(itemId)
        );

        if (!sectionData) {
          delete newSearchState.refinementList[blockId];
        } else {
          newSearchState.refinementList[blockId] = sectionData;
        }

        helpers.setFilteredUrlParams(filterBlocks, newSearchState.refinementList);

        // Switch to the main page on every filters changing
        handleActiveListPage(DEFAULT_PAGINATION_START_PAGE);

        return newSearchState;
      });
    },
    []
  );

  const handleSidebarOpen = useCallback(() => {
    setSidebarOpen(true);
    if (isBrowser()) {
      document.querySelector('html')!.style.overflow = 'hidden';
    }
  }, []);

  const handleCloseSidebar = useCallback(() => {
    setSidebarOpen(false);
    if (isBrowser()) {
      document.querySelector('html')!.style.removeProperty('overflow');
    }
  }, []);

  useClickOutside(wrapperRef, handleCloseSidebar);

  return searchClient && filtersSectionData ? (
    <div data-testid="AlgoliaFilters" className="algolia-filters">
      <InstantSearch
        indexName={indexName}
        searchClient={searchClient}
        refresh
        stalledSearchDelay={500}
        searchState={searchState}
        onSearchStateChange={handleOnSearchStateChange}
      >
        <Configure
          filters={helpers.getDefaultFiltersParams(lang, category, categoryFaceting)}
          hitsPerPage={HitsPerPage}
          analytics={false}
          distinct
          maxValuesPerFacet={HitsPerPage}
        />

        {isSmallDevice ? (
          <Button
            data={filtersSectionData.mobileTriggerBtn}
            clickHandler={handleSidebarOpen}
            className="algolia-filters__trigger"
            customIconSuffix={
              chosenFilterItems?.length ? (
                <span className="algolia-filters__chosen-filter-items-number">
                  {chosenFilterItems.length}
                </span>
              ) : null
            }
          />
        ) : null}

        <div
          className={classNames('algolia-filters__slide', {
            open: isSidebarOpen && isSmallDevice,
          })}
        >
          {isSmallDevice ? <div className="algolia-filters__cover" /> : null}

          <div ref={isSmallDevice ? wrapperRef : null}>
            <FilterPanel
              handleRemoveSelectionData={handleRemoveSelectionData}
              chosenFilterIds={chosenFilterIds}
              filterBlocks={filterBlocks}
              filtersSectionData={filtersSectionData}
              isSmallDevice={isSmallDevice}
              itemsTotal={itemsTotal}
              handleCloseSidebar={handleCloseSidebar}
              category={category}
              clearAllBtn={filtersSectionData.clearAllBtn}
              handleResetSelectionData={handleResetSelectionData}
            />
          </div>
        </div>

        <ChosenFiltersList
          items={chosenFilterItems}
          handleRemoveSelectionData={handleRemoveSelectionData}
          handleResetSelectionData={handleResetSelectionData}
          clearAllBtn={filtersSectionData.clearAllBtn}
          quantityItemsInfo={filtersSectionData.quantityItemsInfo}
          itemsTotal={itemsTotal}
          itemsCurrent={itemsCurrent}
          isSmallDevice={isSmallDevice}
          removeAppliedFilterAriaLabel={filtersSectionData?.removeAppliedFilterAriaLabel}
        />

        <AlgoliaCustomHits
          /* @ts-ignore */
          saveAlgoliaHitsResponse={saveAlgoliaHitsResponse}
          handleHitsResponseActivated={handleHitsResponseActivated}
          isHitsResponseActivated={isHitsResponseActivated}
        />
      </InstantSearch>
    </div>
  ) : null;
};

export default AlgoliaFilters;
