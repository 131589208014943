import React, { FC, ReactElement, useCallback, useEffect, useMemo } from 'react';
import { RefinementListProvided } from 'react-instantsearch-core';
import { connectRefinementList } from 'react-instantsearch-dom';
import { Algolia, UmbracoTags } from '@shared/types';

import CheckBoxCustom from 'components/atoms/CheckBoxCustom';
import Typography from 'components/elements/Typography';

import { IPropsCustomRefinementList } from './models';

import './CustomRefinementList.scss';

export const CustomRefinementList: FC<IPropsCustomRefinementList> = ({
  items,
  refine,
  filterItems,
  blockId,
  title,
  chosenFilterIds,
  handleRemoveSelectionData,
  category,
}): ReactElement | null => {
  const uncheckSelectedDisabledItems = useCallback(
    (itemsToCheck: RefinementListProvided['items']) => {
      itemsToCheck.forEach((item) => {
        if (item.count === 0 && item.isRefined) {
          handleRemoveSelectionData(blockId, parseInt(item.label, 10))();
        }
      });
    },
    []
  );

  useEffect(() => {
    uncheckSelectedDisabledItems(items);
  }, [items]);

  const handleTransformItems = useCallback(
    (
      refinementItems: Algolia.IOriginalRefinementItem[],
      cmsItems: UmbracoTags.IEntityTag[]
    ): Algolia.IRefinementItem[] => {
      const data = cmsItems.map((filterItem: UmbracoTags.IEntityTag) => {
        const refinementItem = refinementItems.find((item) => item.label === String(filterItem.id));

        return {
          ...filterItem,
          count: refinementItem?.count || 0,
          isRefined: refinementItem?.isRefined || false,
          label: filterItem.title,
          value: refinementItem?.value || [String(filterItem.id)],
        };
      });

      return data;
    },
    []
  );

  const handleOnChange = useCallback(
    (item: Algolia.IRefinementItem) => () => {
      refine(item.value);
    },
    [refine]
  );

  const itemsToRender = useMemo(
    () => handleTransformItems(items, filterItems),
    [items, filterItems]
  );

  return (
    <div data-testid="CustomRefinementList" className="custom-refinement-list">
      {title?.[0]?.text ? (
        <Typography data={title} customClass="custom-refinement-list__title" />
      ) : null}

      <div className="custom-refinement-list__content">
        {itemsToRender.map((item: Algolia.IRefinementItem) => (
          <CheckBoxCustom
            key={item.id}
            isChecked={chosenFilterIds?.includes(String(item.id)) || item.id === category}
            labelText={item.title}
            id={`${title}-${item.title}-AlgoliaFilters`}
            name={`${title}-${item.title}-AlgoliaFilters`}
            isDisabled={item.count === 0 || item.id === category}
            onChange={handleOnChange(item)}
          />
        ))}
      </div>
    </div>
  );
};

const ConnectedCustomRefinementList =
  // @ts-ignore
  connectRefinementList<IPropsCustomRefinementList>(CustomRefinementList);

export default ConnectedCustomRefinementList;
