import React, { FC } from 'react';

import { IPropsPaginationItem } from './models';

const PaginationItem: FC<IPropsPaginationItem> = ({
  handlePagingClick,
  numberToDisplay,
  page,
  active,
  paginationItemAriaLabel,
}) => (
  <li className={active === page ? 'active' : ''} data-testid="PaginationItem">
    <button
      type="button"
      aria-label={`${paginationItemAriaLabel || ''}${numberToDisplay}`}
      onClick={handlePagingClick}
    >
      {numberToDisplay}
    </button>
  </li>
);
export default PaginationItem;
