import { UmbracoFilterBlock } from '@shared/types';

export const sortFilterItems = (
  filterItems: UmbracoFilterBlock.IStructure['filterItems'],
  filterItemsOrder?: string
): UmbracoFilterBlock.IStructure['filterItems'] => {
  if (!filterItemsOrder) {
    return filterItems;
  }

  const result: UmbracoFilterBlock.IStructure['filterItems'] = [];

  const orderArray = filterItemsOrder.split(',');

  orderArray.forEach((orderItem) => {
    const data = filterItems.find((item) => item.id === parseInt(orderItem, 10));
    if (data) {
      result.push(data);
    }
  });

  // Fallback, rest of the items not specified in the filterItemsOrder
  filterItems.forEach((item) => {
    const data = orderArray.find((orderItem) => item.id === parseInt(orderItem, 10));
    if (!data) {
      result.push(item);
    }
  });

  return result;
};

export const parseFilterBlocks = (
  param: [UmbracoFilterBlock.IStructure]
): UmbracoFilterBlock.IStructure[] | null =>
  param?.length
    ? param.map((item: UmbracoFilterBlock.IStructure) => ({
        ...item,
        attributeForFaceting: item?.tagsTaxonomy?.[0]?.taxonomy
          ? `${item.tagsTaxonomy[0].taxonomy}.id`
          : '',
        filterItems: sortFilterItems(item.filterItems, item?.filterItemsOrder),
      }))
    : null;
