import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

import Button from 'components/elements/Button';
import PaginationItem from 'components/elements/Pagination/PaginationItem';
import {
  generateLocationQueryString,
  getLocationQueryStringParam,
  getRemovedParamFromLocationQueryString,
  scrollToTarget,
  setUrl,
} from 'utils/browser';
import { DEFAULT_PAGINATION_START_PAGE } from 'utils/constants';

import { PAGE_URL_PARAM } from './constants';
import { getPaginationItems } from './helpers';
import { IPropsPagination } from './models';

import './Pagination.scss';

const Pagination: FC<IPropsPagination> = ({
  data: [{ prevBtn, nextBtn, paginationItemAriaLabel, itemsDelta }],
  handleActiveListPage,
  pageCount,
  active,
  scrollTargetId,
  isSmallDevice,
}) => {
  const [isScrollFinished, setIsScrollFinished] = useState<boolean>(true);

  const paginationItems = useMemo(
    () => getPaginationItems(active, pageCount, itemsDelta),
    [pageCount]
  );
  const getCurrentPageFromUrl = useMemo(
    () => () => {
      const page = getLocationQueryStringParam(PAGE_URL_PARAM);

      if (page?.[0]) {
        return parseInt(page[0], 10) || 0;
      }

      return 0;
    },
    []
  );

  const handleActiveListPageOnLoad = useCallback(() => {
    const currentPage = getCurrentPageFromUrl();

    if (!currentPage) {
      return;
    }

    handleActiveListPage(currentPage);
  }, []);

  useEffect(() => {
    handleActiveListPageOnLoad();
  }, []);

  const setPaginationUrlParams = useCallback((currentPage: number): void => {
    let url: string | null = '';
    if (currentPage) {
      url = generateLocationQueryString(PAGE_URL_PARAM, String(currentPage));
    } else {
      url = getRemovedParamFromLocationQueryString(PAGE_URL_PARAM);
    }

    setUrl(url || '');
  }, []);

  useEffect(() => {
    if (active !== getCurrentPageFromUrl()) {
      setPaginationUrlParams(active);
    }
  }, [active]);

  const handleChangePage = useCallback(
    (page: number) => () => {
      setIsScrollFinished(false);
      handleActiveListPage(page);
      setPaginationUrlParams(page);
      scrollToTarget(scrollTargetId);

      // setIsScrollFinished is fix a bug: disabled changes quicker than scroll ends
      setTimeout(() => {
        setIsScrollFinished(true);
      }, 500);
    },
    []
  );

  return (
    <div data-testid="Pagination" className="pagination">
      <Button
        data={prevBtn}
        disabled={isScrollFinished ? active === DEFAULT_PAGINATION_START_PAGE : false}
        clickHandler={handleChangePage(active - 1)}
        className="pagination__prev"
      />

      {!isSmallDevice ? (
        <ul className="pagination__list">
          {paginationItems.map((item) => {
            if (typeof item === 'string') {
              return (
                <li key={item} className="pagination__gap">
                  <span>{item}</span>
                </li>
              );
            }

            const page = item - 1;

            return (
              <PaginationItem
                key={item}
                handlePagingClick={handleChangePage(page)}
                numberToDisplay={item}
                page={page}
                active={active}
                paginationItemAriaLabel={paginationItemAriaLabel}
              />
            );
          })}
        </ul>
      ) : null}

      <Button
        data={nextBtn}
        disabled={isScrollFinished ? active === pageCount - 1 : false}
        clickHandler={handleChangePage(active + 1)}
        className="pagination__next"
      />
    </div>
  );
};
export default Pagination;
