export const sortByOrder = (a, b) =>
  parseInt(a?.order ? a.order : 99999, 10) - parseInt(b?.order ? b.order : 99999, 10);

export const sortByOrderUrls = (arrayToSort, arrayToSortBy) =>
  arrayToSort?.sort(
    (a, b) =>
      arrayToSortBy.findIndex((item) => item.url === a.url) -
      arrayToSortBy.findIndex((item) => item.url === b.url)
  );

export const matchUrlsToData = (arrayToSort, arrayToSortBy) =>
  arrayToSortBy.map((arrayToSortByItem) => {
    const matchingItem = arrayToSort.find(
      (arrayToSortItem) => arrayToSortItem.url === arrayToSortByItem.url
    );
    if (matchingItem) {
      return { ...matchingItem, ...arrayToSortByItem };
    }

    return arrayToSortByItem;
  });
